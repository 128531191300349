import React from 'react'
import PropTypes from 'prop-types'

import orLogo from '../images/favicon.png'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <img src={orLogo} className="logo-image" alt="Olympic Retreats"/>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Olympic Peninsula Retreats</h1>
                <p>The perfect weekend getaway in the beautiful Olympic Peninsula, Washington. Relax in luxury eco yurts built with sustainbility and comfort hand-in-hand. </p>
                <p>For your next vacation, take a break from the modern world. Disconnect from the city. Reconnect with our roots.</p>
                <h2>Launching 2019</h2>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="https://www.instagram.com/olympicretreats/">Instagram</a></li>
                <li><a href="https://www.facebook.com/olympicretreats/">Facebook</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
